import Edition2018 from './Edition2018'
import Edition2019 from './Edition2019'
import Edition2020 from './Edition2020'
import Edition2021 from './Edition2021'
import Edition2022 from './Edition2022'
import Edition2023 from './Edition2023'
import Edition2024 from './Edition2024'

export class EditionInstance {
  constructor(type, data, userPerms) {
    switch (type) {
      case '2024':
        this.strategy = new Edition2024(data, userPerms)
        break
      case '2023':
        this.strategy = new Edition2023(data, userPerms)
        break
      case '2022':
        this.strategy = new Edition2022(data, userPerms)
        break
      case '2021':
        this.strategy = new Edition2021(data, userPerms)
        break
      case '2020':
        this.strategy = new Edition2020(data, userPerms)
        break
      case '2019':
        this.strategy = new Edition2019(data, userPerms)
        break
      case '2018':
        this.strategy = new Edition2018(data, userPerms)
        break
      default:
        this.strategy = new Edition2021(data, userPerms)
    }
  }

  canCreateEnsProfessionals() {
    return this.strategy.canCreateEnsProfessionals()
  }

  canCreateEnsPremsa() {
    return this.strategy.canCreateEnsPremsa()
  }

  canCreateEnsServeis() {
    return this.strategy.canCreateEnsServeis()
  }

  callIsFinished() {
    return this.strategy.callIsFinished()
  }

  homeComponent() {
    return this.strategy.homeComponent()
  }

  getUrl() {
    return this.strategy.getUrl()
  }

  getConfigData() {
    return this.strategy.getConfigData()
  }

  getConfigRoutes() {
    return this.strategy.getConfigRoutes()
  }

  getRegulationInfo(lang) {
    return this.strategy.getRegulationInfo(lang)
  }

  getPaymentInfo(lang) {
    return this.strategy.getPaymentInfo(lang)
  }

  getStatementOfResponsibility(lang) {
    return this.strategy.getStatementOfResponsibility(lang)
  }

  getCurrentRateByType(type) {
    return this.strategy.getCurrentRateByType(type)
  }

  getBackendType() {
    return this.strategy.getBackendType()
  }

  async canAccesToInfo() {
    return await this.strategy.canAccesToInfo()
  }

  getFiraDaysFilters(t) {
    return this.strategy.getFiraDaysFilters(t)
  }

  getFiraProfessionalActivitiesDaysFilters(t) {
    return this.strategy.getFiraProfessionalActivitiesDaysFilters(t)
  }

  canRegisterToQuickMeetings() {
    return this.strategy.canRegisterToQuickMeetings()
  }

  areQuickMeetingsClosed() {
    return this.strategy.areQuickMeetingsClosed()
  }
}
